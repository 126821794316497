<template>
<div class="body">

    <div class="main">
        <!-- 关闭按钮 -->
        <router-link to="/efficiency">
            <svg t="1645620675149" class="icon close" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1646" width="30" height="30">
                <path d="M514.540358 229.080395c-156.473751 0-283.313577 126.83778-283.313577 283.312554 0 156.472727 126.839827 283.312554 283.313577 283.312554 156.471704 0 283.311531-126.839827 283.311531-283.312554C797.852912 355.918175 671.012062 229.080395 514.540358 229.080395zM651.585617 627.182322l-22.258956 22.25691L514.540358 534.648836 399.749962 649.439232l-22.25691-22.25691L492.281401 512.39295 377.493052 397.6046l22.25691-22.25691 114.790396 114.789373L629.326661 375.34769l22.258956 22.25691L536.795221 512.39295 651.585617 627.182322z" p-id="1647" fill="#515151"></path>
            </svg>
        </router-link>

        <!-- 播放器 -->
        <audio controls ref="audio" :autoplay="false" style="display:none" :src='musicList[selectItem].src'>
            您的浏览器不支持 audio 标签。
        </audio>

        <!-- 歌曲信息界面 -->
        <div class="lrc">暂无歌词...</div>
        <div class="musicplayer">
            <div class="showinfo">
                <el-image class="cover" :src="musicList[selectItem].pic" fit="scale-down"></el-image>
            </div>
            <div class="info">
                <span>{{musicList[selectItem].title}}</span>
                <span style="opacity: 0.6; font-size:0.8rem">-{{musicList[selectItem].artist}}</span>
            </div>

            <!-- 播放模式按钮 -->
            <div class="playmodel" @click="playmodelchange">
                <svg v-show="playmodel == 0" t="1622117643793" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1241" width="20" height="20">
                    <path d="M914.2 705L796.4 596.8c-8.7-8-22.7-1.8-22.7 10V688c-69.5-1.8-134-39.7-169.3-99.8l-45.1-77 47-80.2c34.9-59.6 98.6-97.4 167.4-99.8v60.1c0 11.8 14 17.9 22.7 10l117.8-108.1c5.8-5.4 5.8-14.6 0-19.9L796.4 165c-8.7-8-22.7-1.8-22.7 10v76H758c-4.7 0-9.3 0.8-13.5 2.3-36.5 4.7-72 16.6-104.1 35-42.6 24.4-78.3 59.8-103.1 102.2L513 432l-24.3-41.5c-24.8-42.4-60.5-77.7-103.1-102.2C343 263.9 294.5 251 245.3 251H105c-22.1 0-40 17.9-40 40s17.9 40 40 40h140.3c71.4 0 138.3 38.3 174.4 99.9l47 80.2-45.1 77c-36.2 61.7-103 99.9-174.4 99.9H105c-22.1 0-40 17.9-40 40s17.9 40 40 40l142 0.1h0.2c49.1 0 97.6-12.9 140.2-37.3 42.7-24.4 78.3-59.8 103.2-102.2l22.4-38.3 22.4 38.3c24.8 42.4 60.5 77.8 103.2 102.2 33.1 18.9 69.6 30.9 107.3 35.4 3.8 1.2 7.8 1.8 11.9 1.8l15.9 0.1v55c0 11.8 14 17.9 22.7 10L914.2 725c5.9-5.5 5.9-14.7 0-20z" p-id="1242" fill="#2c2c2c"></path>
                </svg>
                <svg v-show="playmodel == 1" t="1622121128613" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1490" width="20" height="20">
                    <path d="M361.5 727.8c-119.1 0-215.9-96.9-215.9-215.9 0-119.1 96.9-215.9 215.9-215.9 2.3 0 4.6-0.2 6.8-0.6v58.3c0 12.3 14 19.4 23.9 12.1l132.6-97.6c8.1-6 8.1-18.2 0-24.2l-132.6-97.6c-9.9-7.3-23.9-0.2-23.9 12.1v58.1c-2.2-0.4-4.5-0.6-6.8-0.6-39.8 0-78.5 7.9-115 23.4-35.2 15-66.8 36.3-94 63.5s-48.6 58.8-63.5 94c-15.5 36.5-23.4 75.2-23.4 115s7.9 78.5 23.4 115c15 35.2 36.3 66.8 63.5 94s58.8 48.6 94 63.5c36.5 15.5 75.2 23.4 115 23.4 22.1 0 40-17.9 40-40s-17.9-40-40-40zM938.2 396.9c-15-35.2-36.3-66.8-63.5-94s-58.8-48.6-94-63.5c-36.5-15.5-75.2-23.4-115-23.4-22.1 0-40 17.9-40 40s17.9 40 40 40c119.1 0 215.9 96.9 215.9 215.9 0 119.1-96.9 215.9-215.9 215.9-4.1 0-8.1 0.6-11.8 1.8v-60.8c0-12.3-14-19.4-23.9-12.1l-132.6 97.6c-8.1 6-8.1 18.2 0 24.2L629.9 876c9.9 7.3 23.9 0.2 23.9-12.1V806c3.7 1.2 7.7 1.8 11.8 1.8 39.8 0 78.5-7.9 115-23.4 35.2-15 66.8-36.3 94-63.5s48.6-58.8 63.5-94c15.5-36.5 23.4-75.2 23.4-115s-7.8-78.5-23.3-115z" p-id="1491" fill="#2c2c2c"></path>
                    <path d="M512.8 660.6c22.1-0.1 39.9-18.1 39.8-40.2l-1.2-214.1c-0.1-22-18-39.8-40-39.8h-0.2c-22.1 0.1-39.9 18.1-39.8 40.2l1.2 214.1c0.1 22 18 39.8 40 39.8h0.2z" p-id="1492" fill="#2c2c2c"></path>
                </svg>
                <svg v-show="playmodel == 2" t="1622121221154" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1752" width="20" height="20">
                    <path d="M361.5 727.8c-119.1 0-215.9-96.9-215.9-215.9 0-119.1 96.9-215.9 215.9-215.9 2.3 0 4.6-0.2 6.8-0.6v58.3c0 12.3 14 19.4 23.9 12.1l132.6-97.6c8.1-6 8.1-18.2 0-24.2l-132.6-97.6c-9.9-7.3-23.9-0.2-23.9 12.1v58.1c-2.2-0.4-4.5-0.6-6.8-0.6-39.8 0-78.5 7.9-115 23.4-35.2 15-66.8 36.3-94 63.5s-48.6 58.8-63.5 94c-15.5 36.5-23.4 75.2-23.4 115s7.9 78.5 23.4 115c15 35.2 36.3 66.8 63.5 94s58.8 48.6 94 63.5c36.5 15.5 75.2 23.4 115 23.4 22.1 0 40-17.9 40-40s-17.9-40-40-40zM938.2 396.9c-15-35.2-36.3-66.8-63.5-94s-58.8-48.6-94-63.5c-36.5-15.5-75.2-23.4-115-23.4-22.1 0-40 17.9-40 40s17.9 40 40 40c119.1 0 215.9 96.9 215.9 215.9 0 119.1-96.9 215.9-215.9 215.9-4.1 0-8.1 0.6-11.8 1.8v-60.8c0-12.3-14-19.4-23.9-12.1l-132.6 97.6c-8.1 6-8.1 18.2 0 24.2L629.9 876c9.9 7.3 23.9 0.2 23.9-12.1V806c3.7 1.2 7.7 1.8 11.8 1.8 39.8 0 78.5-7.9 115-23.4 35.2-15 66.8-36.3 94-63.5s48.6-58.8 63.5-94c15.5-36.5 23.4-75.2 23.4-115s-7.8-78.5-23.3-115z" p-id="1753" fill="#2c2c2c"></path>
                </svg>
            </div>
            <!-- 时间条 -->
            <div class="block">
                <span class="time">{{cTime}}</span>
                <!-- @change="sliderchange" disabled-->
                <el-slider v-model="timeSlider" disabled :show-tooltip="false"></el-slider>
                <span class="time">{{duration}}</span>
            </div>

            <!-- 播放、换歌控制按钮 -->
            <div class="control">
                <div>
                    <div class="btn" @click="back">
                        <svg t="1621686637525" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6422" width="25" height="25">
                            <path d="M866.561271 957.193455c-13.494339 0-27.346835-3.063779-40.685631-9.716292L112.803135 591.31544c-67.138096-33.529651-67.138096-129.189338 0-162.707732l713.073528-356.161722c13.337773-6.66377 27.190269-9.726525 40.685631-9.726525 47.78533 0 91.086531 38.359656 91.086531 91.075275l0 712.324468C957.647803 918.844032 914.346601 957.193455 866.561271 957.193455z" p-id="6423" fill="#ffffff"></path>
                        </svg>
                    </div>

                </div>

                <div class="btn">
                    <svg v-show="!btnchange" @click="play" t="1621916218943" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7496" width="30" height="30">
                        <path d="M923.427 494.115c0 0-425.517-248.001-659.388-384.303-58.407-35.322-149.748-77.699-149.748 71.567 0 191.617 0 508.64 0 674.874 2.115 84.837 64.329 68.545 103.814 48.91 218.391-127.274 705.325-411.050 705.325-411.050z" p-id="7497" fill="#ffffff"></path>
                    </svg>
                    <svg v-show="btnchange" @click="pause" t="1621683620753" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5350" width="30" height="30">
                        <path d="M757.52 73.107h-62.493c-34.526 0-62.498 27.984-62.498 62.511v749.948c0 34.526 27.974 62.493 62.498 62.493h62.493c34.516 0 62.502-27.968 62.502-62.493v-749.953c-0.001-34.524-27.984-62.509-62.502-62.509z" p-id="5351" fill="#fff"></path>
                        <path d="M320.054 73.107h-62.502c-34.526 0-62.498 27.984-62.498 62.511v749.948c0 34.526 27.974 62.493 62.498 62.493h62.502c34.505 0 62.493-27.968 62.493-62.493v-749.953c-0.001-34.524-27.984-62.509-62.493-62.509z" p-id="5352" fill="#fff"></path>
                    </svg>
                </div>

                <div class="btn" @click="forward">
                    <svg t="1621686557372" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5984" width="25" height="25">
                        <path d="M156.511613 61.693084c13.494339 0 27.346835 3.063779 40.685631 9.715269l713.073528 356.161722c67.138096 33.529651 67.138096 129.189338 0 162.707732l-713.073528 356.161722c-13.337773 6.66377-27.190269 9.726525-40.685631 9.726525-47.78533 0-91.086531-38.359656-91.086531-91.075275L65.425082 152.768359C65.425082 100.041484 108.726284 61.693084 156.511613 61.693084z" p-id="5985" fill="#ffffff"></path>
                    </svg>
                </div>
            </div>

            <!-- 歌曲列表 -->
        </div>
        <div class="ml">
            <ul v-for="index in musicList.length" :key="index">
                <li class="list" style="width:80vw" @click="choosemusic(index)">
                    <div class="lsinfo">
                        <div class="order">{{index}}</div>
                        <div class="name">{{musicList[index-1].title}}-{{musicList[index-1].artist}}</div>
                    </div>
                    <div v-show="(index-1) == selectItem" class="lsicon">
                        <svg t="1622111492640" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4238" width="20" height="20">
                            <path d="M450.56 128h61.44v768H450.56zM512 128h61.44v768h-61.44z" p-id="4239" fill="#1296db"></path>
                        </svg>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
import url from "@/serviceAPI.config.js";

export default {
    data() {
        return {
            playedList: [0],
            timer: null,
            selectItem: 0,
            playmodel: 0, // 1单曲 2列表 0随机
            liststyle: "",
            last: {},
            cTime: '',
            duration: '',
            btnchange: false, // 播放按钮切换
            timeSlider: 0,
            // ctime = 0,
            // musicindex: 0,
            musicList: [{
                title: '',
                artist: '',
                src: "",
                pic: '',
                lrc: "",
                time: Number,
            }],
        }
    },
    methods: {
        getdata() {
            this.$http.get(url.music)
                .then((response) => {
                    this.musicList = response.data.data;

                    // this.$store.commit('music', this.musicList[this.musicindex])
                    console.log(this.musicList)
                })
                .catch((error) => {
                    // console.log(error);
                    // 数据获取失败，再次请求
                    this.getdata()
                });
        },
        timeFormat(time) {
            if (time == 0) {
                return "00:00"
            } else {
                time = Math.round(time * Math.pow(10, 0)) / Math.pow(10, 0)
                var h;
                h = Math.floor(time / 60);
                //计算秒
                //算法：取得秒%60的余数，既得到秒数
                time = time % 60;
                //将变量转换为字符串
                h += '';
                time += '';
                //如果只有一位数，前面增加一个0
                h = (h.length == 1) ? '0' + h : h;
                time = (time.length == 1) ? '0' + time : time;
                return h + ':' + time;
            }
        },
        play() {
            clearInterval(this.timer)
            this.btnchange = true;
            //异步播放
            // let times = 1
            // const that = this
            // let t = setInterval(function () {
            //     if (times === 0) {
            //         clearInterval(t);
            //     }
            //     that.$refs.ap.play()
            //     // console.log(1)
            //     times--
            // }, 100);
            // console.log(this.$refs)
            this.$refs.audio.play()
            this.currentTime()
        },
        pause() {
            clearInterval(this.timer)
            this.btnchange = false;
            this.$refs.audio.pause()
        },

        back() { // 上一曲
            clearInterval(this.timer)
            let playmodel = this.playmodel
            if (playmodel == 0) { //随机播放
                console.log(this.playedList.length)
                if (this.playedList.length != 1) {
                    this.playedList.pop()
                    let num = this.playedList.pop()
                    this.selectItem = num
                    // const num = this.playedList.pop() + 1
                    this.choosemusic(num + 1)
                } else {
                    this.forward()
                }
            } else if (playmodel == 1) { // 单曲循环
                this.$refs.audio.load()
                this.$nextTick(() => {
                    // dom元素更新后执行，因此这里能正确打印更改之后的值

                    this.play()

                })
            } else { //列表循环
                let index = (this.selectItem - 1) % this.musicList.length + 1
                this.choosemusic(index)
            }

        },
        forward() { // 下一曲
            clearInterval(this.timer)
            let playmodel = this.playmodel
            if (playmodel == 0) { //随机播放
                // Math.floor(Math.random() * (m - n + 1) + n) 生成n到m的随机整数
                let index = Math.round(Math.random() * (this.musicList.length - 1) + 1)
                let selectItem = this.selectItem + 1
                while (index == selectItem) {
                    index = Math.round(Math.random() * (this.musicList.length - 1) + 1)
                }
                // console.log(index)
                this.choosemusic(index)
            } else if (playmodel == 1) { // 单曲循环
                this.$refs.audio.load()
                this.$nextTick(() => {
                    // dom元素更新后执行，因此这里能正确打印更改之后的值

                    this.play()

                })
            } else { //列表循环
                let index = (this.selectItem + 1) % this.musicList.length + 1
                this.choosemusic(index)
            }
        },
        // sliderchange(num) {
        //     // clearInterval(this.timer)
        //     this.timeSlider = num
        //     let time = num * this.$refs.audio.duration * 0.01
        //     // time = parseInt(time)
        //     console.log(time)
        //     console.log(this.$refs)
        //     if ('fastSeek' in this.$refs.audio) {
        //         // 改变audio.currentTime的值
        //         this.$refs.audio.fastSeek(time)
        //     } else {
        //         console.log(time)
        //         this.$refs.audio.currentTime = time

        //     }
        // this.$refs.audio.fastSeek(time)
        // this.$refs.audio.fastSeek(time)
        // this.$refs.ap.audio.seek(time)
        // console.log(this.$refs)
        // },
        choosemusic(index) {
            this.playedList.push(index - 1)
            console.log(this.playedList)
            // this.musicindex = index - 1
            this.selectItem = index - 1
            // this.$store.commit('lastmusic')
            // this.pause()
            this.play()
            // console.log("choose")
            // this.$store.commit('music', this.musicList[this.musicindex])
            // this.$emit("sw");
        },
        playmodelchange() {
            this.playmodel = (this.playmodel + 1) % 3
        },
        currentTime() {
            let time = this.$refs.audio.currentTime
            this.musictime = time
            let music = this.musicList[this.selectItem]
            this.duration = this.timeFormat(music.time)
            this.cTime = this.timeFormat(time)

            this.timeSlider = this.$refs.audio.currentTime / music.time * 100
            // console.log(this.timeSlider)
            const that = this
            this.timer = setTimeout(function () {
                that.currentTime()
                if (time >= music.time) {
                    that.forward()
                }
            }, 1000)
        },
        mChange() {
            // 

            console.log(2333)
        }
    },
    created() {
        this.selectItem = 0
        this.getdata()

    },
    watch: {
        selectItem: function () {
            this.$refs.audio.load()

            // console.log(this.$refs)
            this.$nextTick(() => {
                // dom元素更新后执行，因此这里能正确打印更改之后的值

                this.play()

            })
        }
    }
}
</script>

<style scoped>
/* .body::-webkit-scrollbar {
    display: none
} */

.body {
    width: 100vw;
    height: 100vh;
    background: url('../../assets/musicbg.png') 0 / cover fixed;
    /* padding: 2vh 0vw; */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.main {
    width: 90vw;
    height: 95vh;
    line-height: 2;
    border-radius: 5px;
    background: rgba(255, 255, 255, .3);
    box-shadow: 3px 3px 6px 3px rgba(0, 0, 0, .3);
    /* overflow: hidden; */
    position: relative;
}

.cover {
    position: absolute;
    top: 3vh;
    left: 5vw;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    box-shadow: 0px 0px 9px 9px #bababa;
    /* display: none; */
    /* -9px -9px 17px #bababae8; */
}

.info {
    width: 30vw;
    height: 25px;
    overflow: hidden;
    position: absolute;
    top: 3vh;
    left: calc(5vw + 130px);
}

.info:hover {
    position: relative;
    z-index: 999;
}

.playmodel {
    opacity: 0.8;
    position: absolute;
    top: calc(3vh + 6px);
    left: 74vw;
}

.block {
    position: absolute;
    left: 5vw;
    width: 80vw;
    top: calc(3vh + 103px);
    display: flex;
    justify-content: center;
}

.time {
    font-size: 0.5rem;
    line-height: 38px;
    width: 35px;
}

.el-slider {
    width: 55vw;
    margin: 0px 5px;
}

.block>>>.el-slider__button {
    width: 6px !important;
    height: 6px !important;
}

.control {
    width: 90vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    top: calc(4vh + 137px);
    /* filter: blur(2px); */
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    border-radius: 46px;
    opacity: 0.6;
    border-radius: 50%;
    /* background: #e0e0e0; */
    box-shadow: 5px 5px 10px #bebebe,
        -5px -5px 10px #ffffff;
}

.btn:active {
    opacity: 0.4;
    background-color: #cccaca;
    box-shadow: none;
}

.mlinfo {
    margin-left: 6px;
    height: 18px;
    display: flex;
    justify-content: flex-start;
}

.ml::-webkit-scrollbar {
    display: none
}

.ml {
    height: calc(88vh - 195px);
    /* overflow-x: scroll; */
    overflow-y: scroll;
    /*解决ios上滑动不流畅*/
    -webkit-overflow-scrolling: touch;
    position: absolute;
    left: 5vw;
    top: calc(4vh + 200px);
    border-radius: 20px;
    /* background: #e0e0e0; */
    box-shadow: 2px 2px 5px #bebebe,
        -2px -2px 5px #ffffff;
}

.lsicon {
    display: flex;
    align-items: center;
}

.lsicon::svg {
    margin-top: 1px;
}

.list {
    width: 80vw;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid #fff; */
    border-radius: 10px;
    margin: 2px 0px;
    overflow: hidden;
}

.lsinfo {
    display: flex;
    justify-content: flex-start;
}

.name {
    margin: 0px;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    width: 60vw;
    height: 30px;
    overflow: hidden;
}

.order {
    margin: 0px 5px;
}

.player {
    background: none;
    font-size: 1rem;
}

.lrc {
    opacity: 0.8;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc(3vh + 25px);
    left: calc(5vw + 100px);
    width: 40vw;
    height: 80px;
}

.close {
    position: absolute;
    top: -15px;
    right: -15px;
    z-index: 3;
    opacity: 0.6;
    overflow: visible;
}
</style>
